<template>
  <div class="vehicle-view-page container" v-if="vehicle">
    <h1>Edit vehicle</h1>
    <hr />

    <form>
      <div class="col-md-12 d-flex flex-wrap">
        <div class="mb-3 me-3">
          <label for="unit" class="form-label">Unit</label>
          <input type="text" class="form-control" id="unit" v-model="unit" />
        </div>

        <div class="mb-3 me-3">
          <label for="type" class="form-label">Type</label>
          <SelectWithSearch
            inputId="type"
            :inputValue="type?.name"
            :items="vehicleTypes"
            @search="onVehicleTypeSearch"
            @clear="onVehicleTypeClear"
          />
        </div>

        <div class="mb-3 me-3">
          <label for="vinNumber" class="form-label">VIN number</label>
          <input type="text" class="form-control" id="vinNumber" v-model="vinNumber" />
        </div>

        <div class="mb-3 me-3">
          <label for="plateNumber" class="form-label">Plate number</label>
          <input type="text" class="form-control" id="plateNumber" v-model="plateNumber" />
        </div>

        <div class="mb-3 me-3">
          <label for="companyName" class="form-label">Company name</label>
          <input type="text" class="form-control" id="companyName" v-model="companyName" />
        </div>
      </div>

      <div class="col-md-12 d-flex flex-wrap">
        <div class="mb-3 me-3 form-check">
          <label for="isActive" class="form-check-label">Active</label>
          <input type="checkbox" class="form-check-input" id="isActive" v-model="isActive" />
        </div>

        <div class="mb-3 me-3 form-check">
          <label for="isInService" class="form-check-label">Service</label>
          <input type="checkbox" class="form-check-input" id="isInService" v-model="isInService" />
        </div>

        <div class="mb-3 me-3 form-check">
          <label for="hasTurboLogo" class="form-check-label">Turbo logo</label>
          <input type="checkbox" class="form-check-input" id="hasTurboLogo" v-model="hasTurboLogo" />
        </div>

        <div class="mb-3 me-3 form-check">
          <label for="hasMmmLogo" class="form-check-label">MMM logo</label>
          <input type="checkbox" class="form-check-input" id="hasMmmLogo" v-model="hasMmmLogo" />
        </div>

        <div class="mb-3 me-3 form-check">
          <label for="isTeam" class="form-check-label">Team</label>
          <input type="checkbox" class="form-check-input" id="isTeam" v-model="isTeam" />
        </div>
      </div>

      <div class="col-md-12 d-flex flex-wrap">
        <div class="mb-3 me-3">
          <label for="onHoldTill" class="form-label">On hold</label>
          <input type="datetime-local" class="form-control" id="onHoldTill" v-model="onHoldTill" />
        </div>

        <div class="mb-3 me-3">
          <label for="registrationExpiresAt" class="form-label">Registration</label>
          <input type="date" class="form-control" id="registrationExpiresAt" v-model="registrationExpiresAt" />
        </div>

        <div class="mb-3 me-3">
          <label for="coiExpiresAt" class="form-label">COI</label>
          <input type="date" class="form-control" id="coiExpiresAt" v-model="coiExpiresAt" />
        </div>
      </div>

      <div class="col-md-12 d-flex flex-wrap">
        <div class="col-md-1 mb-3 me-3">
          <label for="length" class="form-label">Length</label>
          <input type="number" min="0" class="form-control" id="length" v-model="length" />
        </div>
        <div class="col-md-1 mb-3 me-3">
          <label for="width" class="form-label">Width</label>
          <input type="number" min="0" class="form-control" id="width" v-model="width" />
        </div>
        <div class="col-md-1 mb-3 me-3">
          <label for="height" class="form-label">Height</label>
          <input type="number" min="0" class="form-control" id="height" v-model="height" />
        </div>
        <div class="col-md-1 mb-3 me-3">
          <label for="doorWidth" class="form-label">Door width</label>
          <input type="number" min="0" class="form-control" id="doorWidth" v-model="doorWidth" />
        </div>
        <div class="col-md-1 mb-3 me-3">
          <label for="doorHeight" class="form-label">Door height</label>
          <input type="number" min="0" class="form-control" id="doorHeight" v-model="doorHeight" />
        </div>
        <div class="col-md-1 mb-3 me-3">
          <label for="capacity" class="form-label">Capacity</label>
          <input type="number" min="0" class="form-control" id="capacity" v-model="capacity" />
        </div>
      </div>

      <div class="mb-3">
        <label class="form-label">Contacts</label>
        <div class="row">
          <template v-for="(contact, index) in contacts">
            <div class="card col-md-3 mb-3 ms-3">
              <span class="close"><i class="fa fa-times" title="Remove" @click="() => removeContact(index)"></i></span>
              <div class="card-body">
                <h5 class="card-title">
                  {{ contact.person.name }}

                  <i
                    class="contact fa fa-phone"
                    :class="{ main: contact.isMain }"
                    @click="!contact.isMain && setContactIsMain(index)"
                    title="Call"
                  ></i>
                </h5>

                <div class="mb-2">
                  <h6 class="card-subtitle text-muted">Roles</h6>

                  <span
                    :class="{
                      badge: true,
                      'me-2': true,
                      'contact-role': true,
                      'bg-secondary': !hasFlag(contact.roles, VehicleContactRole.DRIVER),
                      'bg-primary': hasFlag(contact.roles, VehicleContactRole.DRIVER),
                    }"
                    @click="
                      contact.roles = hasFlag(contact.roles, VehicleContactRole.DRIVER)
                        ? removeFlag(contact.roles, VehicleContactRole.DRIVER)
                        : addFlag(contact.roles, VehicleContactRole.DRIVER)
                    "
                    >Driver</span
                  >

                  <span
                    :class="{
                      badge: true,
                      'me-2': true,
                      'contact-role': true,
                      'bg-secondary': !hasFlag(contact.roles, VehicleContactRole.OWNER),
                      'bg-primary': hasFlag(contact.roles, VehicleContactRole.OWNER),
                    }"
                    @click="
                      contact.roles = hasFlag(contact.roles, VehicleContactRole.OWNER)
                        ? removeFlag(contact.roles, VehicleContactRole.OWNER)
                        : addFlag(contact.roles, VehicleContactRole.OWNER)
                    "
                    >Owner</span
                  >

                  <span
                    :class="{
                      badge: true,
                      'me-2': true,
                      'contact-role': true,
                      'bg-secondary': !hasFlag(contact.roles, VehicleContactRole.DISPATCHER),
                      'bg-primary': hasFlag(contact.roles, VehicleContactRole.DISPATCHER),
                    }"
                    @click="
                      contact.roles = hasFlag(contact.roles, VehicleContactRole.DISPATCHER)
                        ? removeFlag(contact.roles, VehicleContactRole.DISPATCHER)
                        : addFlag(contact.roles, VehicleContactRole.DISPATCHER)
                    "
                    >Dispatcher</span
                  >
                </div>
                <p class="card-text">Lang: {{ contact.person.langs.join(', ') }}</p>
                <p class="card-text">
                  Email:
                  <template v-if="contact.person.email" v-for="email in contact.person.email.split(/[\/|\,]/gi).map(i => i.trim())">
                    <a :href="`mailto:${email}`" class="card-link">{{ email }}</a>
                    <br />
                  </template>
                  <span v-else>-</span>
                </p>
                <p class="card-text">
                  Phone:
                  <template v-if="contact.person.phone" v-for="phone in contact.person.phone.split(/[\/|\,]/gi).map(i => i.trim())">
                    <a :href="`callto:${phone}`" class="card-link">{{ phone }}</a>
                    <br />
                  </template>
                  <span v-else>-</span>
                </p>
              </div>
            </div>
          </template>
          <div class="new-contact col-md-2">
            <SelectWithSearch :clearOnSelect="true" :items="availablePersons" @search="onContactSearch" />
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label class="form-label">Equipments</label>
        <div class="row">
          <template v-for="(vehicleEquipment, index) in vehicleEquipments">
            <div class="card col-md-2 mb-3 ms-3 flex flex-row">
              <div class="card-body">
                <p class="card-text">{{ vehicleEquipment.equipment.name }}</p>
              </div>
              <span class="close"><i class="fa fa-times" title="Remove" @click="() => removeEquipment(index)"></i></span>
            </div>
          </template>
          <div class="new-equipment col-md-2">
            <SelectWithSearch :clearOnSelect="true" :items="availableEquipments" @search="onEquipmentSearch" />
          </div>
        </div>
      </div>

      <div class="mb-3 col-md-12 home-location">
        <label for="location" class="form-label">Location</label>
        <div class="d-flex flex-wrap align-items-center">
          <SelectWithSearch :inputValue="location?.postalCode" :items="locations" @search="onLocationSearch" @clear="onLocationClear" />
          <span class="ms-3">{{ location?.formattedAddress }}</span>
        </div>
      </div>

      <div class="mb-3">
        <label for="comments" class="form-label">Comments</label>
        <textarea type="text" class="form-control" id="comments" v-model="comments"></textarea>
      </div>

      <div class="mb-3">
        <label for="notes" class="form-label">Notes</label>
        <i
          v-if="vehicle.notesHistory.length > 0"
          class="fa fa-history ms-2 text-muted"
          :title="
            `Updated by ${vehicle.notesHistory[0].createdByAccount?.login ?? 'Unknown'} at ${dateTimeFormatter.format(
              new Date(vehicle.notesHistory[0].createdAt),
            )}&#10;Previous  value:&#10;${vehicle.notesHistory[0].fromValue}`
          "
          role="button"
        ></i>
        <textarea type="text" class="form-control col-md-8" id="notes" v-model="notes"></textarea>
      </div>

      <button type="button" class="btn btn-primary" :disabled="!currentAccount.hasPermission('vehicles.edit')" @click="save">Save</button>
      <button type="button" class="btn btn-danger ms-2" :disabled="!currentAccount.hasPermission('vehicles.remove')" @click="remove">
        Delete
      </button>

      <span class="ms-3 text-muted">
        <template v-if="vehicle.updatedAt">
          Updated by <b>{{ vehicle.updatedByAccount?.login ?? 'Unknown' }}</b> at
          <b>{{ dateTimeFormatter.format(new Date(vehicle.updatedAt)) }}</b>
        </template>
        <template v-else>
          Created by <b class="font-weight-bold">{{ vehicle.createdByAccount?.login ?? 'Unknown' }}</b> at
          <b>{{ dateTimeFormatter.format(new Date(vehicle.createdAt)) }}</b>
        </template>
      </span>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { VehicleContactRole } from '@/types/vehicle-contact';
import { dateTimeFormatter, dateToInputDateTime, inputDateTimeToDate, hasFlag, addFlag, removeFlag } from '@/utils';
import SelectWithSearch from '@/components/SelectWithSearch';
import {
  findVehicle,
  updateVehicle,
  deleteVehicle,
  findVehicleType,
  getVehicleTypeList,
  getPersonList,
  getEquipmentList,
  getLocationList,
} from '@/api';

export default {
  name: 'VehicleEditPage',
  components: { SelectWithSearch },
  computed: {
    ...mapGetters(['account/current']),
    currentAccount() {
      return this['account/current'];
    },
    availablePersons() {
      return this.persons
        .filter(i => !this.contacts.find(j => j.personId === i.id))
        .map(i => ({
          title: i.name,
          subtitle: [i.email ? `Email: ${i.email}` : null, i.phone ? `Phone: ${i.phone}` : null].filter(i => !!i).join('<br>'),
          onClick: () => this.addContact(i),
        }));
    },
    availableEquipments() {
      return this.equipments
        .filter(i => !this.vehicleEquipments.find(j => j.equipmentId === i.id))
        .map(i => ({
          title: i.name,
          onClick: () => this.addEquipment(i),
        }));
    },
  },
  data: () => ({
    unit: '',
    vinNumber: '',
    plateNumber: '',
    onHoldTill: null,
    isActive: true,
    isInService: true,
    hasTurboLogo: false,
    hasMmmLogo: false,
    registrationExpiresAt: null,
    coiExpiresAt: null,
    isTeam: false,
    comments: null,
    notes: null,
    companyName: '',
    length: 0,
    width: 0,
    height: 0,
    doorWidth: 0,
    doorHeight: 0,
    capacity: 0,

    vehicleTypes: [],
    type: null,

    persons: [],
    contacts: [],

    equipments: [],
    vehicleEquipments: [],

    locations: [],
    location: null,

    vehicle: null,

    dateTimeFormatter,
    hasFlag,
    addFlag,
    removeFlag,
    VehicleContactRole,
  }),
  methods: {
    async onVehicleTypeSearch(search) {
      const response = await getVehicleTypeList(100, 0, search);

      if (response?.errors) {
        this.$toast.error(response.errors[0].message);
        return;
      }

      this.vehicleTypes = response.data.vehicleTypes.map(i => ({ title: i.name, onClick: () => this.setVehicleType(i) }));
    },

    onVehicleTypeClear() {
      this.setVehicleType(null);
    },

    async setVehicleType(value) {
      this.type = value;
      console.log(`Vehicle type "${value?.id}" was selected`);
    },

    async onContactSearch(search) {
      const response = await getPersonList(100, 0, search);

      if (response?.errors) {
        this.$toast.error(response.errors[0].message);
        return;
      }

      this.persons = response.data.persons;
    },

    async addContact(person) {
      this.contacts.push({
        person,
        personId: person.id,
        isMain: this.contacts.length === 0,
        roles: 0,
      });
    },

    removeContact(index) {
      const [contact] = this.contacts.splice(index, 1);
      if (!contact) {
        return;
      }

      if (contact.isMain) {
        if (this.contacts[0]) {
          this.contacts[0].isMain = true;
        }
      }
    },

    setContactIsMain(index) {
      const contact = this.contacts[index];
      const mainContact = this.contacts.find(i => i.isMain);

      contact.isMain = true;

      if (mainContact) {
        mainContact.isMain = false;
      }
    },

    async onEquipmentSearch(search) {
      const response = await getEquipmentList(100, 0, search);

      if (response?.errors) {
        this.$toast.error(response.errors[0].message);
        return;
      }

      this.equipments = response.data.equipments;
    },

    async addEquipment(equipment) {
      this.vehicleEquipments.push({ equipment, equipmentId: equipment.id });
    },

    removeEquipment(index) {
      this.vehicleEquipments.splice(index, 1);
    },

    async onLocationSearch(search) {
      const response = await getLocationList(100, 0, search);

      if (response?.errors) {
        this.$toast.error(response.errors[0].message);
        return;
      }

      this.locations = response.data.locations.map(i => ({
        title: i.formattedAddress,
        onClick: () => this.setLocation(i),
      }));
    },

    onLocationClear() {
      this.setLocation(null);
    },

    setLocation(location) {
      this.location = location;
    },

    async update(withReset = true) {
      try {
        if (withReset) {
          this.vehicle = null;
        }

        const id = this.$route.params.id;
        if (!id) {
          return;
        }

        const response = await findVehicle(id);
        if (response?.errors) {
          throw new Error(response.errors[0].message);
        }

        this.vehicle = response?.data?.vehicle;
        if (!this.vehicle) {
          throw new Error('Vehicle not found');
        }

        this.unit = this.vehicle.unit;
        this.type = this.vehicle.type;
        this.vinNumber = this.vehicle.vinNumber;
        this.plateNumber = this.vehicle.plateNumber;
        this.onHoldTill = this.vehicle.onHoldTill ? dateToInputDateTime(this.vehicle.onHoldTill) : null;
        this.isActive = this.vehicle.isActive;
        this.isInService = this.vehicle.isInService;
        this.hasTurboLogo = this.vehicle.hasTurboLogo;
        this.hasMmmLogo = this.vehicle.hasMmmLogo;
        this.registrationExpiresAt = this.vehicle.registrationExpiresAt;
        this.coiExpiresAt = this.vehicle.coiExpiresAt;
        this.isTeam = this.vehicle.isTeam;
        this.comments = this.vehicle.comments;
        this.notes = this.vehicle.notes;
        this.companyName = this.vehicle.companyName;
        this.length = this.vehicle.length;
        this.width = this.vehicle.width;
        this.height = this.vehicle.height;
        this.doorWidth = this.vehicle.doorWidth;
        this.doorHeight = this.vehicle.doorHeight;
        this.capacity = this.vehicle.capacity;
        this.contacts = JSON.parse(JSON.stringify(this.vehicle.contacts));
        this.vehicleEquipments = JSON.parse(JSON.stringify(this.vehicle.equipments));
        this.location = this.vehicle.location;
      } catch (error) {
        console.error(error);
        this.$toast.error(error.message);
      }
    },

    async save() {
      try {
        const data = {};

        if (!this.type) {
          return this.$toast.error("Type can't be empty.");
        }

        if (this.unit?.trim() !== this.vehicle.unit) {
          data.unit = this.unit?.trim();
        }

        if (this.vinNumber?.trim() !== this.vehicle.vinNumber) {
          data.vinNumber = this.vinNumber?.trim();
        }

        if (this.plateNumber?.trim() !== this.vehicle.plateNumber) {
          data.plateNumber = this.plateNumber?.trim();
        }

        const onHoldTill = this.onHoldTill ? inputDateTimeToDate(this.onHoldTill) : null;
        if (onHoldTill !== this.vehicle.onHoldTill) {
          data.onHoldTill = onHoldTill;
        }

        if (this.isActive !== this.vehicle.isActive) {
          data.isActive = this.isActive;
        }

        if (this.isInService !== this.vehicle.isInService) {
          data.isInService = this.isInService;
        }

        if (this.hasTurboLogo !== this.vehicle.hasTurboLogo) {
          data.hasTurboLogo = this.hasTurboLogo;
        }

        if (this.hasMmmLogo !== this.vehicle.hasMmmLogo) {
          data.hasMmmLogo = this.hasMmmLogo;
        }

        if (this.registrationExpiresAt !== this.vehicle.registrationExpiresAt) {
          data.registrationExpiresAt = this.registrationExpiresAt || null;
        }

        if (this.coiExpiresAt !== this.vehicle.coiExpiresAt) {
          data.coiExpiresAt = this.coiExpiresAt || null;
        }

        if (this.isTeam !== this.vehicle.isTeam) {
          data.isTeam = this.isTeam;
        }

        if (this.comments !== this.vehicle.comments) {
          data.comments = this.comments;
        }

        if (this.notes !== this.vehicle.notes) {
          data.notes = this.notes;
        }

        if (this.companyName?.trim() !== this.vehicle.companyName) {
          data.companyName = this.companyName?.trim();
        }

        if (this.length !== this.vehicle.length) {
          data.length = this.length;
        }

        if (this.width !== this.vehicle.width) {
          data.width = this.width;
        }

        if (this.height !== this.vehicle.height) {
          data.height = this.height;
        }

        if (this.doorWidth !== this.vehicle.doorWidth) {
          data.doorWidth = this.doorWidth;
        }

        if (this.doorHeight !== this.vehicle.doorHeight) {
          data.doorHeight = this.doorHeight;
        }

        if (this.capacity !== this.vehicle.capacity) {
          data.capacity = this.capacity;
        }

        if (this.type.id !== this.vehicle.typeId) {
          data.typeId = this.type.id;
        }

        if (!this.contacts.length) {
          return this.$toast.error('Select at least one contact person.');
        }

        {
          const contactsCopy = [...this.contacts];
          const existingContactsCopy = [...this.vehicle.contacts];
          for (let i = 0; i < contactsCopy.length; i++) {
            const contact = contactsCopy[i];
            const existingContactIndex = existingContactsCopy.findIndex(
              j => j.personId === contact.personId && j.isMain === contact.isMain && j.roles === contact.roles,
            );
            if (existingContactIndex === -1) {
              break;
            }

            contactsCopy.splice(i--, 1);
            existingContactsCopy.splice(existingContactIndex, 1);
          }

          if (contactsCopy.length || existingContactsCopy.length) {
            data.contacts = this.contacts.map(i => ({ personId: i.personId, isMain: i.isMain, roles: i.roles }));
          }
        }

        data.equipments = this.vehicleEquipments.map(i => i.equipmentId);

        const locationId = this.location?.id ?? null;
        if (locationId !== this.vehicle.locationId) {
          data.locationId = locationId;
        }

        const response = await updateVehicle(this.$route.params.id, data);
        if (response.errors) {
          throw new Error(response.errors[0].message);
        }

        this.$toast.success('Vehicle was updated.', { duration: 2000 });
        setTimeout(() => this.$router.push({ name: 'vehicles' }), 2000);
        this.update(false);
      } catch (err) {
        console.error(err);
        this.$toast.error(err.message);
      }
    },

    async remove() {
      try {
        const response = await deleteVehicle(this.$route.params.id);
        if (response.errors) {
          throw new Error(response.errors[0].message);
        }

        this.$router.push({ name: 'vehicles' });
        this.$toast.success('Vehicle was deleted.', { duration: 2000 });
      } catch (err) {
        this.$toast.error(err.message);
      }
    },
  },
  activated() {
    this.update();
  },
  watch: {
    '$route.params.id': {
      handler() {
        this.update();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.vehicle-view-page {
  padding: 2em 0;
}

.control input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.control button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.home-location label {
  display: block;
}

.home-location input {
  width: auto;
  display: inline-block;
}

.home-location button {
  vertical-align: top;
}

.card {
  color: #212529;
}

.close {
  cursor: pointer;
  margin-left: auto;
}

.owner {
  color: gold;
}

.owner.far {
  cursor: pointer;
}

.contact {
  color: red;
  cursor: pointer;
}

.contact.main {
  color: green;
  cursor: default;
}

.contact-role {
  cursor: pointer;
  user-select: none;
}
</style>
